import React from "react"
import { Row}  from 'react-bootstrap'

import Layout from '../../components/Layout'
import ArticlePreview from '../../components/ArticlePreview'
import * as styles from '../../styles/list-item.module.css'

export default function ServerNetwork() {

  
    return (
      <Layout>  
          <Row className={styles.list}>
            <ArticlePreview key='pfsense'
                   title='PfSense/Router'
                   link = {"/tutorials/pfsense"}
                   published= "2022-03-19"
                   image = "/images/tutorials/pfsense/logo.jpg"

                />  
            <ArticlePreview key='sql19alwaysOn'
                   title='SQL 2019 Always ON High Availability'
                   link = {"/tutorials/sql-server-2019-always-on-high-availability"}
                   published= "2022-04-19"
                   image = "/images/icons/sql2019.jpg"

                />  

            </Row> 
    </Layout>
    )
}